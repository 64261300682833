import React from "react"
import HeroAnimation from "./hero-animation";
import Madlib from "./madlib"
import '../scss/hero.scss'

export default function Hero ({title, colors}) {
  return (
    <header className="hero relative">
      <HeroAnimation colors={colors} />
      <div className="hero-description absolute left-1/2 top-1/2 w-full">
        {!title && <p> Hi there, I'm <Madlib />.</p>}
        {title && <p>{title}</p>}
      </div>
    </header>
  )
}