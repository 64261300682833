import safeString from 'safe-string';

export function radiansToDegrees(radians) {
  return radians * 180 / Math.PI
}

export function degreesToRadians(deg) {
  return deg * Math.PI/180;
}

export function random(min, max) {
  if(max){
    return Math.random() * (max - min) + min;
  } else {
    return Math.random() * min;
  }
}

export function getDeviceType(){
  var deviceType = (navigator.userAgent.match(/iPad/i)) === "iPad" ? "iPad" : (navigator.userAgent.match(/iPhone/i))  === "iPhone" ? "iPhone" : (navigator.userAgent.match(/Android/i)) === "Android" ? "Android" : (navigator.userAgent.match(/BlackBerry/i)) === "BlackBerry" ? "BlackBerry" : "null";
  return deviceType;
}

export function hasTouchEvents(){
  return(typeof(window.ontouchstart) !== 'undefined');
}

export function getExtension(filename) {
  return filename.toLowerCase().split('.').pop();
}


export const trimHtmlString = (passedString, length = 100) => {
  const regexp = /(<([^>]+)>)/ig
  const sanitizedString = passedString.replace(regexp, '');
  let trimmedString = sanitizedString.substring(0, length);
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
  return safeString(trimmedString);
}