import React, { useState, useEffect } from "react"
import { gsap, Power3 } from 'gsap';
import { Transition } from 'react-transition-group';

import '../scss/madlib.scss';

export default function Madlib ({
  phrases = [
    'a Creative Coder',
    'a Musician',
    'an Animator',
    'a Prototyper',
    'a Freelancer', 
    'Richard Lehmann'
  ]}) {

  const [ phraseIndex, setPhraseIndex ] = useState(0);
  const PHRASE_DELAY_SECONDS = 3.5;

  useEffect(() => {
    gsap.delayedCall(PHRASE_DELAY_SECONDS, advancePhrase, [phraseIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const advancePhrase = (currentPhraseIndex) => {   
    const newPhraseIndex = currentPhraseIndex + 1;
    setPhraseIndex(newPhraseIndex%phrases.length)
    gsap.delayedCall(PHRASE_DELAY_SECONDS, advancePhrase, [newPhraseIndex]);
  }

  const onEnter = ( node ) => {
    const chars = Array.from(node.getElementsByClassName('madlib-char'));
    const tl = gsap.timeline({delay: 1});
    tl.set(node, {display: 'inline-block'});
    tl.set(chars, {autoAlpha: 0});
    tl.to(chars, 0, {autoAlpha: 1, stagger:.05});
  }

  const onExit = (node) => {
    node.style.setProperty('display', 'inline-block');
    const [ highlightElement ] = Array.from(node.getElementsByClassName('madlib-highlight'));
    const chars = Array.from(node.getElementsByClassName('madlib-char'));
    const tl = gsap.timeline();
    tl.set(chars, {autoAlpha: 1}, 0);
    tl.set(highlightElement, {autoAlpha: 1}, 0);
    tl.from(highlightElement, .3, {width: 0,  ease: Power3.easeOut});
    tl.to(chars.reverse(), 0, {autoAlpha: 0, stagger: .05});
    tl.to(highlightElement, .3, {autoAlpha: 0, ease: Power3.easeOut});
    tl.set(node, {display: 'none'});
    tl.duration(1).play();
  }

  const renderChar = (char, i) => {
    if (char.match(/^\s*$/g)) {
      return char;
    }

    return (
      <span
        className="madlib-char"
        key={i}
        style={{
          display: 'inline-block',
          position: 'relative'
        }}
      >
        {char}
      </span>
    );
  }

  const renderPhrase = (phrase, i) => {
    const isActive = phraseIndex === i;
    return (
      <Transition key={i} in={isActive} timeout={1000} onEnter={onEnter} onExit={onExit}>
        <span key={i} className="madlib-phrase">
          <span className="madlib-highlight" />
          {phrase.split('').map(renderChar)}
        </span>
      </Transition>
    );
  }

  return (
   <span className="madlib">
    {phrases.map(renderPhrase)}
   </span>
  )
}
