import React, { useEffect, useRef } from "react"
import { Application, Sprite, Filter, Texture, filters } from "pixi.js";
import { gsap, Power1 } from 'gsap';
import { random } from "../utils/helpers";
import FragmentShader from '../shaders/threshold.frag';
import chroma from "chroma-js";

export default function HeroAnimation ({colors = ['#f00', '#00f']}) {
  const containerRef = useRef(null);
  let texture = null;
  let cursor = [0, 0];

  useEffect(() => {
      const el = containerRef.current;
      const viewWidth = el.offsetWidth;
      const viewHeight = el.offsetHeight;
      const app = new Application({width: viewWidth, height: viewHeight, transparent: true });
      el.appendChild(app.view);
      app.stage.position.set(viewWidth * 0.5, viewHeight * 0.5);
      drawTexture();
      colors.map(color => addParticleContainer(color, app));
      el.addEventListener('mousemove', updateCursor);
      el.addEventListener('touchmove', updateCursor);

      return (()=> {
        el.removeEventListener('mousemove', updateCursor);
        el.removeEventListener('touchmove', updateCursor);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addParticleContainer = (color, app) => {
    const [r,g,b] = chroma(color).rgb();
    const container = new Sprite();
    const thresholdFilter = new Filter( null,  FragmentShader, { threshold: 0.9 } );
    const colorMatrix = new filters.ColorMatrixFilter();
    colorMatrix.matrix = [
      r / 255, 0, 0, 0, 0,
      0, g / 255, 0, 0, 0, 
      0, 0, b / 255, 0, 0, 
      0, 0, 0, 0.8, 0
    ];
    container.filters = [thresholdFilter, colorMatrix];
    spawnParticles(container);
    app.stage.addChild(container);
  }

  const updateCursor = (e) => {
    const el = e.target;
    const viewWidth = el.offsetWidth;
    const viewHeight = el.offsetHeight;
    var x = (e.pageX || e.originalEvent.touches[0].pageX) - viewWidth/2;
    var y = (e.pageY || e.originalEvent.touches[0].pageY) - viewHeight/2;
    y = Math.min(viewHeight/2-140, y);
    setCursor(x,y);
  }

  const setCursor = (x,y) => {
    cursor = [x,y];
  }

  const drawTexture = ()=> {
    const canvas = document.createElement('canvas');
    const size = 100;
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    const gradient = context.createRadialGradient( canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2 );
    gradient.addColorStop(0, 'rgba(255,255,255,1)');
    gradient.addColorStop(1, 'rgba(255,255,255,0)');
    context.fillStyle = gradient;
    context.fillRect( 0, 0, canvas.width, canvas.height);
    texture = Texture.from(canvas);
  }

  const spawnParticles = (container) => {
    for(let i = 0; i < 32; i++){
      const sprite = new Sprite(texture);
      sprite.anchor.set(0.5);
      sprite.scale.x = sprite.scale.y = random(0.4,1.6);
      container.addChild(sprite);
      moveParticle(sprite);
    }
  }
  
  const moveParticle = (particle) => {
    const x = particle.homing ? cursor[0] + random(-10,10) :  cursor[0] + random(-140,140);
    const y =	particle.homing ? cursor[1] + random(-10,10) :  cursor[1] + random(-140,140);
    gsap.to(particle.position, random(0.2,1.0), {x:x, y:y, ease: Power1.easeInOut, onComplete:moveParticle, onCompleteParams:[particle]});
    particle.homing = !particle.homing;
  }

  return (
    <div ref={containerRef} className="absolute left-0 top-0 w-full h-full overflow-hidden"></div>
  )
}

